import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {FiLinkedin} from 'react-icons/fi'
import { useRef } from 'react';
// npm install emailjs-com is necessary to make it work
import emailjs from 'emailjs-com';

// Function name should be preferably on uppercase
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_k9oqb8q', 'template_13f0jgs', form.current, 'GCt-rKL2n4LM-MRxU')

    // this line is given so that after submitting the form, the text (name, email, message) disappears
    e.target.reset()
  };
  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail/>
            <h4>Email</h4>
            <h5>isratnowshin.bd@gmail.com</h5>
            <a href='mailto:isratnowshin.bd@gmail.com'>Send Message</a>
          </article>
          <article className='contact__option'>
            <RiMessengerLine/>
            <h4>Messenger</h4>
            <h5>Israt Nowshin</h5>
            <a href='https://m.me/israt.nowshin/'>Send Message</a>
          </article>
          <article className='contact__option'>
            <FiLinkedin/>
            <h4>LinkedIn</h4>
            <h5>Israt Nowshin</h5>
            <a href='https://www.linkedin.com/in/israt-nowshin-a2934197/'>Send Message</a>
          </article>
          {/* <article className='contact__option'>
            <RiMessengerLine/>
            <h4>Whatsapp</h4>
            <h5>+4915785098448</h5>
            <a href='https://api.whatsapp.com/send?phone=+4915785098448'>Send Message</a>
          </article> */}

        </div>
        {/* end of contact option */}
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name='message' rows='7'placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

      </div>
    </section>
  )
}

export default Contact