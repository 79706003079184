import React from 'react'
import './About.css'
import ME from '../../assets/picture-2.jpg'
import {FaAward} from 'react-icons/fa'
import {FaUsers} from 'react-icons/fa'
import {BiFolderOpen} from 'react-icons/bi'
const About = () => {
  return (
    <section id='about'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>
      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about_icon'></FaAward>
              <h5>Experience</h5>
              <small>2+ Years Working Experience</small>
            </article>
              

            <article className='about__card'>
              <FaUsers className='about_icon'></FaUsers>
              <h5>Education</h5>
              <small>Computer Science</small>
            </article>
              
            <article className='about__card'>
              <BiFolderOpen className='about_icon'></BiFolderOpen>
              <h5>Projects</h5>
              <small>10+ Completed Projects</small>
            </article>
          </div>

          <p>
          After graduation I have joined as a Lecturer at Asian University for Women where I taught Basic Programming and Data structure courses. Later on during my Master studies in Germany, I worked as a Research Assistant at University Hospital in the Data integration Centre. 

I thrive in dynamic environments, where I can leverage my expertise in backend and data to drive innovative solutions. From Web-based applications to ETL pipelines, I've consistently demonstrated an ability to solve problems and find unique solutions.
          </p>

          <a href='#contact' className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>  
    </section>
  )
}

export default About