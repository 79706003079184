import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>Fullstack Developer</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Web Developement</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Backend Development with Django</p>
            </li><li>
              <BiCheck className='service__list-icon'/>
              <p>Work with Relational Database</p>
            </li><li>
              <BiCheck className='service__list-icon'/>
              <p>Knowledge in non-relational database</p>
            </li>
          </ul>
        </article>
{/* ENDS of UI/UX */}
<article className='service'>
          <div className='service__head'>
            <h3>Data Engineer</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>ETL pipeline using Python</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Worked with Postgres Database</p>
            </li><li>
              <BiCheck className='service__list-icon'/>
              <p>Experience working with HAPI FHIR</p>
            </li><li>
              <BiCheck className='service__list-icon'/>
              <p>Knowledge of Apache Kafka and AWS</p>
            </li>
          </ul>
        </article>
{/* WEB development */}
<article className='service'>
          <div className='service__head'>
            <h3>Data Scientist</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Medical Mining</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Fake Audio Detection</p>
            </li><li>
              <BiCheck className='service__list-icon'/>
              <p>Knowledge in Scikit Learn and Tensorflow</p>
            </li><li>
              <BiCheck className='service__list-icon'/>
              <p>Information Retrieval System creation</p>
            </li>
          </ul>
        </article>
{/* ENDS of Content Creator */}
      </div>
    </section>
  )
}

export default services