import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'

const footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Israt Nowshin</a>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>about</a></li>
        <li><a href='#experience'>experience</a></li>
        <li><a href='#services'>services</a></li>
        <li><a href='#portfolio'>portfolio</a></li>
        <li><a href='#testimonials'>testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className='footer__socials'>
        <a href='https://facebook.com'><FaFacebookF/></a>
        <a href='https://instagram.com'><FaInstagram/></a>
        <a href='https://linkedin.com'><FaLinkedin/></a>

      </div>

      <div className="footer__copyright">
        <small>&copy; Israt Nowshin. All Rights Reserved.</small>

      </div>
    </footer>
  )
}

export default footer