import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio-1.jpeg'
import IMG2 from '../../assets/portfolio-2.png'
import IMG3 from '../../assets/portfolio-3.jpeg'
import IMG4 from '../../assets/website-4.png'
import IMG5 from '../../assets/website-5.webp'
import IMG6 from '../../assets/website-6.png'

const data = [
  {
    id:1,
    image: IMG1,
    title: 'ETL using Python and Postgres',
    github: 'https://github.com/inowshin19/ETL',
    demo: 'https://github.com' 
  },
  {
    id:1,
    image: IMG2,
    title: 'Gutenberg Fiction Genre Detection',
    github: 'https://github.com/inowshin19/Gutenberg-Fiction-Genre-Detection',
    demo: 'https://github.com' 
  },
  {
    id:1,
    image: IMG3,
    title: 'DeepFake Audio Detection',
    github: 'https://github.com/inowshin19/Fake-Audio-Detection',
    demo: 'https://github.com' 
  }
]

const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>

        {
          data.map(({id, image, title, github,demo}) => {
            return(
        <article key={id} className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={image} alt={title}/>
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta">
            <a href={github} className='btn' target='_blank'>Github</a>
            {/* <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a> */}
          </div>  
        </article>
            )
          })
        }       

      </div>
    </section>
  )
}

export default portfolio