import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {FiDribbble} from 'react-icons/fi'

const headerSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/israt-nowshin-a2934197/' target='_blank'><BsLinkedin/></a>
        <a href='https://github.com/inowshin19' target='_blank'><BsGithub/></a>
        {/* <a href='https://dribble.com' target='_blank'><FiDribbble/></a> */}
        
    </div>
  )
}

export default headerSocials