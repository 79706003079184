import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/picture-1.jpg'
import HeaderSocial from './headerSocials'

const header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Israt Nowshin</h1>
        <h5 className="text-light">FullStack Developer & Data Engineer/Scientist</h5>
        <CTA />
        <HeaderSocial />

      
      <div className="me">
        <img src={ME} alt='me'/>
      </div>

      <a href='#contact' className='scroll__down'> Scroll Down</a>
      </div>
    </header>
  )
}

export default header