import React from 'react'
import './testimonial.css'
import AVTR1 from '../../assets/picture-3.jpeg'
import AVTR2 from '../../assets/picture-4.jpeg'
import AVTR3 from '../../assets/picture-5.jpeg'
import AVTR4 from '../../assets/picture-3.jpeg'


// import Swiper core and required modules
import {Pagination} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: AVTR1,
    name: 'Abdullahil Kafi',
    review: 'I highly recommend Israt for any project or position that requires expertise in Python, ETL management, and machine learning. I had the pleasure of working with her during our masters studies, where we collaborated on machine learning projects. Israt\'s technical skills and knowledge in these areas are impressive and she consistently demonstrated a strong work ethic and attention to detail throughout the project. She has also knowledge about ins and outs of complex mathematical and statistical problems which shows her strong analytical ability. Her ability to manage ETL processes and effectively communicate technical concepts to stakeholders is truly remarkable. She is a collaborative team player and always willing to go above and beyond to ensure the success of the project. I have no doubt that Israt will be a valuable asset to any organization that she works with. I highly recommend her without hesitation.'
  },
  {
    avatar: AVTR2,
    name: 'Lubaba Islam',
    review: 'I have had my privilege to know Israt from my undergrad classes at AUW. Amongst many qualities she possesses, her analytical mind and flair in the technical projects are worth mentioning. She demonstrated her database knowledge & programming skills in every Computer Science project at AUW which inspired her peers and teachers. She was an excellent tutor/ TA for our Science & Math center which led her to serve as a full time instructor in CS after graduation. I am also honored to have known her as one of my finest comrades in AUW IT club where she played an exceptional role in coordinating all the activities and securing funds for our STEM oriented club programs. She is a consistent player in a team setting and above all, an amazing human to work with- a great combination as a gem of a person! I am proud of her current achievements and will always cheer for her future endeavors. She will be a great asset in any team she joins.'
  },
 
]
const testimonial = () => {
  return (
    <section id='testimonial'>
      <h5>Review from Teammates</h5>
      <h2>Testimonials</h2>
{/* REFER TO SWIPER.JS FOR THE SWIPING OPTIONS */}
      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {
          data.map(({avatar, name, review}, index) =>
          {
            return(
              <SwiperSlide key={index} className='testimonial'>
              <div className="client__avatar">
                <img src={avatar} alt="Avatar 1" />
                
              </div>
              <h5>{name}</h5>
                <small className='client__review'>
                  {review}
                  </small>
            </SwiperSlide>
            )

          })
        }
       
      
      
      </Swiper>
    </section>
  )
}

export default testimonial